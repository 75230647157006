import React from "react"
import * as S from "./featured-blog.styles"
import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"

const FeaturedBlog = ({ title, date, uri, featuredImage }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      wheel: file(relativePath: { eq: "wheel.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  if (!title) return null
  return (
    <S.Wrapper url={`${uri}` ?? "/"}>
      <S.GridContainer container>
        <S.GridItem item md={6} xs={12}>
          <S.TextWrapper>
            <S.Featured>Featured</S.Featured>
            {title && <S.Title>{title}</S.Title>}
            {date && <S.Date>{date}</S.Date>}
            <S.Button>Read More</S.Button>
          </S.TextWrapper>
          <S.BgImage img={staticQuery.wheel} />
        </S.GridItem>
        <Grid item md={6} xs={12}>
          {featuredImage && <S.BlogImage img={featuredImage.node} />}
        </Grid>
      </S.GridContainer>
    </S.Wrapper>
  )
}

export default FeaturedBlog
