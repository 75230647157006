import React, { useRef, useEffect, useState } from "react"
import * as S from "./gallery-slider.styles"
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "../../../node_modules/slick-carousel/slick/slick.css"
import Slider from "react-slick"
import Arrow from "../../assets/arrow.svg"
import { graphql, useStaticQuery } from "gatsby"
import { useParallax, Parallax } from "react-scroll-parallax"

const GallerySlider = ({ title, description, slides }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  })

  let slider = useRef(null)
  const next = () => {
    slider.slickNext()
  }

  const previous = () => {
    slider.slickPrev()
  }

  const staticQuery = useStaticQuery(graphql`
    query {
      wheel: file(relativePath: { eq: "bg-header-blog.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  if (!slides) return null
  return (
    <S.Wrapper id="examples">
      <S.WheelWrapper>
        <Parallax rotateZ={[0, 360]}>
          <S.Wheel className="spinner" img={staticQuery.wheel} />
        </Parallax>
      </S.WheelWrapper>

      <S.CustomContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} alignItems="center">
            {title && <S.Title>{title}</S.Title>}
          </Grid>
          <Grid item xs={12} sm={12} md={6} alignItems="center">
            {description && <S.Description>{description}</S.Description>}
          </Grid>
        </Grid>
        <S.SliderWrapper>
          <S.CustomSlider
            ref={c => (slider = c)}
            infinite={true}
            speed={1000}
            // autoplay={true}
            arrows={false}
            slidesToShow={1}
            slidesToScroll={1}
            // draggable={false}
            // beforeChange={(oldIndex, newIndex) => setValue(newIndex)}
            style={{ height: "100%" }}
          >
            {slides.map(({ image }, index) => (
              <S.Image
                arPaddingPercentage={isMobile ? 0 : 60}
                img={image}
                key={`image-slide-${index}`}
              />
            ))}
          </S.CustomSlider>
          <S.ArrowButton onClick={previous} disableRipple>
            <S.ArrowWrapper>
              <S.CustomArrow />
            </S.ArrowWrapper>
          </S.ArrowButton>
          <S.ArrowButton onClick={next} disableRipple className="right">
            <S.ArrowWrapper>
              <S.CustomArrow />
            </S.ArrowWrapper>
          </S.ArrowButton>
        </S.SliderWrapper>
        <S.ButtonResponsiveContainer>
          <S.ArrowResponsiveButton onClick={previous} disableRipple>
            <Arrow />
          </S.ArrowResponsiveButton>
          <S.ArrowResponsiveButton
            onClick={next}
            className="right"
            disableRipple
          >
            <Arrow />
          </S.ArrowResponsiveButton>
        </S.ButtonResponsiveContainer>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default GallerySlider
