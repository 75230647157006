import React, { useContext } from "react"
import * as S from "./mission-section.styles"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import { colorsContext } from "../../provider"

const MissionSection = ({ image, title, description, button }) => {
  const { setIsFormDrawerOpen } = useContext(colorsContext)
  return (
    <S.Wrapper id="our-mission" className={`${button && "bottom"}`}>
      <S.CustomRow>
        <S.CustomGrid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <S.Image img={image} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Container sx={{ height: "100%" }}>
              <S.TextWrapper>
                {title && <S.Title>{title}</S.Title>}
                {description && (
                  <S.Description className="treez">{description}</S.Description>
                )}
                {button && (
                  <S.ButtonWrapper>
                    <CustomButton
                      onClick={() => {
                        setIsFormDrawerOpen(true)
                      }}
                    >
                      {button}
                    </CustomButton>
                  </S.ButtonWrapper>
                )}
              </S.TextWrapper>
            </Container>
          </Grid>
        </S.CustomGrid>
      </S.CustomRow>
    </S.Wrapper>
  )
}

export default MissionSection
