import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled.section`
  background-color: transparent;
  padding-top: 2em;
  padding-bottom: 2em;
  position: relative;
  z-index: 4;
`

export const Title = styled.h2`
  text-align: center;
  max-width: 560px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.text.primary};
  font-style: normal;
  font-weight: 400;
  margin: auto auto 1em;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(38)};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    text-align: center;
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: ${({ theme }) => theme.typography.pxToRem(58)};
  }
`

export const Stat = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(64)};
  line-height: ${({ theme }) => theme.typography.pxToRem(78)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(84)};
    line-height: ${({ theme }) => theme.typography.pxToRem(90)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(120)};
    line-height: ${({ theme }) => theme.typography.pxToRem(145)};
    letter-spacing: -0.05em;
    font-weight: 600;
  }

  &.treez {
    font-family: ${({ theme }) => theme.fonts.primary}!important;
  }
`

export const StatWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-weight: 400;
  line-height: ${({ theme }) => theme.typography.pxToRem(21)};
  font-size: ${({ theme }) => theme.typography.pxToRem(28)};
  &.treez {
    font-family: ${({ theme }) => theme.fonts.primary}!important;
  }
`
