import React, { useState, useRef, useEffect } from "react"
import { useTheme, useMediaQuery } from "@mui/material"

import * as S from "./info-slider.styles"

const InfoSlider = ({ slides }) => {
  const [value, setValue] = useState(0)
  const [active, setActive] = useState(true)

  let slider = useRef()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

  useEffect(() => {
    if (slider) {
      slider.slickGoTo(value)
    }
  }, [value])

  const settings = {
    variableWidth: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => {
      setValue(next)
      setActive(true)
    },
    afterChange: () => {
      setActive(false)
    },
  }

  if (!slides) return null

  return (
    <S.Wrapper id="overview-2">
      <S.CustomContainer>
        <S.TextWrapper>
          <S.Title>{slides[value]?.topTitle}</S.Title>
        </S.TextWrapper>
        {isMobile && (
          <S.ImageWrapper>
            <S.LeftImage img={slides[value].image} />
          </S.ImageWrapper>
        )}
        <S.SliderWrapper>
          <S.CustomSlider ref={c => (slider = c)} {...settings}>
            {slides.map(({ title }, index) => (
              <div key={`title-${index}`} style={{ width: "auto" }}>
                {title && (
                  <S.SliderTitle
                    onClick={() => {
                      setValue(index)
                    }}
                  >
                    {title}
                  </S.SliderTitle>
                )}
              </div>
            ))}
          </S.CustomSlider>
        </S.SliderWrapper>
        <S.BottomWrapper>
          <S.IndicatorWrapper>
            {slides.map(({ title }, index) => (
              <S.ThumbWrapper
                key={`thumb-${index}`}
                onClick={() => {
                  setValue(index)
                }}
              >
                <S.Thumb className={index === value ? "active" : ""} />
              </S.ThumbWrapper>
            ))}
          </S.IndicatorWrapper>
          <S.Description>{slides[value].description}</S.Description>
        </S.BottomWrapper>
      </S.CustomContainer>
      {!isMobile && (
        <S.ImageWrapper className={active ? "animated" : ""}>
          <S.LeftImage
            img={slides[value].image}
            className={slides[value]?.leftImage ? "small" : ""}
          />
        </S.ImageWrapper>
      )}
      {!isMobile && slides[value]?.leftImage && (
        <S.SemiCircleImage
          className={active ? "animated" : ""}
          img={slides[value]?.leftImage}
        />
      )}
    </S.Wrapper>
  )
}

export default InfoSlider
