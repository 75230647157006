import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import { Accordion, Grid, Typography } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"

export const Wrapper = styled(SectionWrapper)`
  font-family: ${({ theme }) => theme.fonts.primary};
  background: #f1f8f4;
  padding: 4rem 0;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 6rem 0 2em 0;
  }
`

export const AccordionContainer = styled.div`
  .MuiAccordion-root:before {
    display: none;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
  button:first-child {
    margin-right: 1.25rem;
  }
`
export const Content = styled.div`
  p {
    margin-top: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #222222;
  }
  margin-bottom: 3rem;
`
export const CustomAccordion = styled(Accordion)`
  background: transparent;
  border: none;
  box-shadow: none;
  .MuiAccordionSummary-root {
    padding-left: 0;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  &.MuiPaper-elevation {
    margin: 0;
  }
  &.Mui-expanded {
    .MuiAccordionSummary-root {
      min-height: 48px;
    }
    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`
export const ImageGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    min-height: 855px;
  }
`
export const Image = styled(CustomImage)`
  display: none;
  //position: absolute;
  //top: 50%;
  //transform: translateY(-50%);
  img {
    object-fit: contain !important;
  }
  &.bottom {
    width: 100%;
    img {
      object-position: bottom;
    }
  }
  &.right {
    ${({ theme }) => theme.breakpoints.down("xl")} {
      width: 50vw;
      img {
        object-position: right !important;
      }
    }
    max-height: 583px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
    position: absolute;
  }
`
export const Icon = styled(ExpandMore)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 26px;
`

export const Description = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #5f5f5f;
`

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 500px;
  }
`
