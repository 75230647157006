import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../utils/get-layout-utils"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_WhatOurCustomersSay {
            ...WhatOurCustomersSay
          }
          ... on WpPage_Pagebuilder_Layouts_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Pagebuilder_Layouts_InfoSlider {
            ...InfoSlider
          }
          ... on WpPage_Pagebuilder_Layouts_MissionSection {
            ...MissionSection
          }
          ... on WpPage_Pagebuilder_Layouts_StatsSection {
            ...StatsSection
          }
          ... on WpPage_Pagebuilder_Layouts_GallerySlider {
            ...GallerySlider
          }
          ... on WpPage_Pagebuilder_Layouts_LetsChatParallax {
            ...LetsChatParallax
          }
          ... on WpPage_Pagebuilder_Layouts_Testimonials {
            ...Testimonials
          }
          ... on WpPage_Pagebuilder_Layouts_BookDemoSection {
            ...BookDemoSection
          }
          ... on WpPage_Pagebuilder_Layouts_TabsSection {
            ...TabsSection
          }
          ... on WpPage_Pagebuilder_Layouts_Hero {
            ...Hero
          }
          ... on WpPage_Pagebuilder_Layouts_ArticlesBanner {
            ...ArticlesBanner
          }
          ... on WpPage_Pagebuilder_Layouts_BlogsPage {
            ...BlogsPage
          }
          ... on WpPage_Pagebuilder_Layouts_UpdatesPage {
            ...UpdatesPage
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
          istreezpage
          isLightHeader
        }
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, slug, pageBuilder, title } = data.wpPage
  const layouts = pageBuilder.layouts || []

  useEffect(() => {
    if (seo?.canonical) {
      seo.canonical = `${process.env.GATSBY_WEBSITE_URL}${seo.canonical}`
    }
  }, [])
  return (
    <Layout {...pageBuilder.pageConfiguration} seo={seo}>
      {layouts.map(layout => getPageLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
