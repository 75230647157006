import React, { useState } from "react"
import * as S from "./tabs-section.styles"
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Container,
  Grid,
} from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "@mui/material/Typography"
import CustomButton from "../../components/custom-button/custom-button.component"
import { Expand } from "@mui/icons-material"
import parse from "html-react-parser"

const TabsSection = ({ items }) => {
  const [selectedButton, setSelectedButton] = useState(0)
  const staticQuery = useStaticQuery(graphql`
    query {
      mobile: file(relativePath: { eq: "hand-iphone.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  const handleClick = selected => {
    setSelectedButton(selected)
  }

  return (
    <S.Wrapper contained id="benefits">
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <S.ButtonContainer>
            <CustomButton
              className={`lightBorder ${selectedButton == 0 && "selected"}`}
              onClick={() => handleClick(0)}
            >
              About Treez
            </CustomButton>
            <CustomButton
              className={`lightBorder ${selectedButton == 1 && "selected"}`}
              onClick={() => handleClick(1)}
            >
              About Mosaic
            </CustomButton>
          </S.ButtonContainer>
          {items[selectedButton].content && (
            <S.Content>{parse(items[selectedButton].content)}</S.Content>
          )}
          <S.AccordionContainer>
            {items[selectedButton]?.tabs.map((item, index) => (
              <S.CustomAccordion key={item}>
                <AccordionSummary
                  expandIcon={<S.Icon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <S.Title>{item.title}</S.Title>
                </AccordionSummary>
                <AccordionDetails>
                  <S.Description>{item.description}</S.Description>
                </AccordionDetails>
              </S.CustomAccordion>
            ))}
          </S.AccordionContainer>
        </Grid>
        <S.ImageGrid item xs={12} md={5} style={{ position: "relative" }}>
          <S.Image
            img={items[selectedButton].image}
            className={selectedButton == 0 ? "right" : "bottom"}
          />
        </S.ImageGrid>
      </Grid>
    </S.Wrapper>
  )
}

export default TabsSection
