import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import Typography from "@mui/material/Typography"
import Slider from "react-slick"
import { Container } from "@mui/material"
import { keyframes } from "@emotion/react"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

export const Wrapper = styled(SectionWrapper)`
  //padding-top: 2em;
  //padding-bottom: 2em;
  background-color: ${({ theme }) => theme.palette.tertiary.light};
  position: relative;
  padding: 3.5em 0;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-bottom: 0;
    padding-top: 0;
  }

  overflow: hidden;
`

export const CustomContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    min-height: 800px;
  }
`

export const LeftImage = styled(CustomImage)`
  transition: 0.3s all ease;
  margin-bottom: 0;
  //max-height: 7750px;
  max-width: 50vw;
  width: 50vw;
  max-height: 770px;

  &.small {
    max-height: 530px;
    max-width: 38vw;
    width: 38vw;
    img {
      max-width: 30vw;
      width: 30vw;
      max-height: 530px;
    }
  }
  img {
    max-height: 770px;
    max-width: 50vw;
    width: 50vw;
    object-fit: contain !important;
    object-position: center;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-height: 400px;
    max-width: 100%;
    width: 100%;

    img {
      max-height: 400px;
      max-width: 100%;
      width: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: 2rem;
    &.small {
      margin-top: unset;
    }
  }
`

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
`

export const ImageWrapper = styled.div`
  margin: 0;
  line-height: 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  &.animated {
    animation: ${FadeIn} 0.5s ease;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background: linear-gradient(
        180deg,
        rgba(241, 248, 244, 0) 0%,
        #f1f8f4 100%
      );
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 4;
  }
`

export const SemiCircleImage = styled(CustomImage)`
  height: 100%;

  img {
    //width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    max-height: 631px;
  }
`

export const BottomWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TextWrapper = styled.div``

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text.titles};
  max-width: 540px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(39)};
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: 58px;
    font-weight: 400;
    padding-bottom: 0.5em;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(50)};
    line-height: 38px;
    font-weight: 400;
    padding-bottom: 0.5em;
    max-width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: 38px;
    font-weight: 400;
    padding-bottom: 0.5em;
  }
`

export const Description = styled(Typography)`
  max-width: 560px;
  color: ${({ theme }) => theme.palette.text.content};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 21px;
    font-weight: 400;
  }
`

export const CustomSlider = styled(Slider)`
  width: 100%;

  .slick-current {
    opacity: 1;
  }

  .slick-cloned {
    opacity: 0.3;
  }

  .slick-track {
    display: flex;
  }

  .slick-list {
    overflow: unset;
    //clip-path: inset(-100vw -100vw -100vw 0);
    margin: 0 -12px;
  }

  .slick-slide {
    opacity: 0.3;
    transition: 0.5s ease all;

    &.slick-active {
      &.slick-current {
        opacity: 1;
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    .slick-slide {
      margin: 0 12px;
    }
  }
`

export const SliderWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 2em;
  }
`

export const SliderTitle = styled.h3`
  cursor: pointer;
  //padding-left: 16px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(64)};
  font-family: ${({ theme }) => theme.fonts.primary};
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(100)};
    line-height: ${({ theme }) => theme.typography.pxToRem(100)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(140)};
    line-height: ${({ theme }) => theme.typography.pxToRem(140)};
    letter-spacing: -0.05em;
  }
`

export const IndicatorWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 215px;
  }
`

export const Thumb = styled.div`
  background-color: #d0d5dd;
  height: 4px;
  cursor: pointer;
  width: 100%;

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const ThumbWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1 1 0;
`
