import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import * as S from "./testimonials.styles"
import { Container } from "@mui/material"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "../../../node_modules/slick-carousel/slick/slick.css"
import parse from "html-react-parser"
import Arrow from "../../assets/arrow.svg"
import { InfoContainer } from "./testimonials.styles"

const Testimonials = ({ testimonials }) => {
  const [value, setValue] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)
  const [progress, setProgress] = useState(0)
  const NUM_TESTIMONIALS = testimonials.length
  let slider = useRef()

  useEffect(() => {
    if (slider) {
      slider.slickGoTo(value)
      const timer = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress > 100) {
            if (value < NUM_TESTIMONIALS - 1) {
              setProgress(0)
              setValue(value + 1)
            } else {
              setValue(0)
            }

            return 0
          }
          return prevProgress + 1
        })
      }, 100)
      return () => {
        clearInterval(timer)
      }
    }
  }, [value])

  const next = () => {
    setProgress(0)
    slider.slickNext()
  }

  const previous = () => {
    setProgress(0)
    slider.slickPrev()
  }

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => {
      setProgress(0)
      setValue(next)
    },
  }

  if (!testimonials) return null

  return (
    <S.Wrapper id="testimonials">
      <Container>
        <S.SliderWrapper>
          <S.CustomSlider
            ref={c => (slider = c)}
            style={{ height: "100%" }}
            {...settings}
          >
            {testimonials.map(({ description, name, role, company }, index) => (
              <S.DescriptionWrapper>
                <S.Description className="treez" key={`testimonial-${index}`}>
                  {description && parse(description)}
                </S.Description>
                <S.InfoContainer>
                  <S.InfoText className="treez">
                    {name && <strong>{name + ", "}</strong>}
                    {role && <span>{role}</span>}
                    {company && <span> at {company}</span>}
                  </S.InfoText>
                </S.InfoContainer>
              </S.DescriptionWrapper>
            ))}
          </S.CustomSlider>
          <S.ArrowButton onClick={previous} disableRipple>
            <S.ArrowWrapper>
              <S.CustomArrow />
            </S.ArrowWrapper>
          </S.ArrowButton>
          <S.ArrowButton onClick={next} className="right" disableRipple>
            <S.ArrowWrapper>
              <S.CustomArrow />
            </S.ArrowWrapper>
          </S.ArrowButton>
        </S.SliderWrapper>
        <S.PhotoWrapper>
          {testimonials.map(({ photo }, index) => (
            <S.ItemContainer
              key={`photo-${index}`}
              onClick={() => slider.slickGoTo(index)}
            >
              {value === index && (
                <S.CustomCircularProgress
                  variant="determinate"
                  size={92}
                  value={progress}
                />
              )}
              <S.Photo
                img={photo}
                onClick={() => {
                  setValue(index)
                  setProgress(0)
                }}
              />
            </S.ItemContainer>
          ))}
        </S.PhotoWrapper>

        <S.ButtonResponsiveContainer>
          <S.ArrowResponsiveButton disableRipple onClick={previous}>
            <Arrow />
          </S.ArrowResponsiveButton>
          <S.ArrowResponsiveButton
            disableRipple
            onClick={next}
            className="right"
          >
            <Arrow />
          </S.ArrowResponsiveButton>
        </S.ButtonResponsiveContainer>
      </Container>
    </S.Wrapper>
  )
}

export default Testimonials
