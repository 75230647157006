import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import { Grid, Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  padding-top: 1em;
  padding-bottom: 4em;
  overflow: hidden;
  position: relative;
  z-index: 3;
  &.bottom {
    padding-bottom: 0;
  }
`
export const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`
export const CustomRow = styled.div`
  max-width: 1800px;
  margin: auto;
`

export const CustomGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    .MuiGrid-item {
      ${({ invert }) =>
        !invert ? "&:first-child{ order: 2 } &:last-child{ order:  1}" : ""}
    }
  }
`

export const Image = styled(CustomImage)`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-height: 800px;
    img {
      max-height: 800px;
      object-fit: contain !important;
      object-position: left;
    }
  }
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0.5em;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(38)};
    line-height: ${({ theme }) => theme.typography.pxToRem(42)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: ${({ theme }) => theme.typography.pxToRem(50)};
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-weight: 400;

  &.treez {
    font-family: ${({ theme }) => theme.fonts.primary}!important;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 21px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 500px;
  }
`
