import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import Slider from "react-slick"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

export const Wrapper = styled(SectionWrapper)`
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: transparent;
  z-index: 4;
`

export const SliderWrapper = styled.div`
  position: relative;
  height: auto;
`

export const CustomSlider = styled(Slider)`
  &.slick-slider {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      margin: 1em 0 !important;
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
`

export const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  width: 100%;
  padding: 1em 0 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`

export const Description = styled(Typography)`
  &.treez {
    font-family: ${({ theme }) => theme.fonts.primary}!important;
  }
  text-align: center;
  max-width: 910px;
  margin: auto;
  color: ${({ theme }) => theme.palette.text.content};
  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(28)};
    line-height: 36px;
    font-weight: 400;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const InfoText = styled.p`
  &.treez {
    font-family: ${({ theme }) => theme.fonts.primary}!important;
  }
  text-align: center;
  font-size: 1rem;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 1em;

  span {
    &.name {
      font-weight: 600;
    }
  }
`

export const ArrowButton = styled(IconButton)`
  display: none;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    &.right {
      top: 50%;
      left: 93%;
      transform: translateY(-50%);
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const ArrowWrapper = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 73, 20, 0.2);
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  &:hover {
    background-color: #004914;
    svg {
      fill: white;
    }
  }
`

export const CustomArrow = styled(ArrowBackIcon)`
  fill: #004914;
  width: 24px;
  height: 24px;
`

export const ButtonResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 1em;
  margin-bottom: 1em;
`

export const ArrowResponsiveButton = styled(IconButton)`
  display: none;
  @media (max-width: 900px) {
    display: block;
    &.right {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const ItemContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const CustomCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.secondary.main};
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
  circle {
    stroke-width: 2;
  }
`

export const Photo = styled(CustomImage)`
  height: 80px;
  width: 80px;
  max-width: 100%;
  border-radius: 50%;
  img {
    max-width: 100%;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover !important;
  }
`
