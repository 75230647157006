import React from "react"
import * as S from "./stats-section.styles"
import { Container, Grid } from "@mui/material"
import CountUp from "react-countup"
import { separateNumbersFromLetters } from "../../utils/utils"

const StatsSection = ({ title, stats }) => {
  if (!stats) return null
  return (
    <S.Wrapper>
      <Container>
        {title && <S.Title>{title}</S.Title>}
        <Grid container>
          {stats.map(({ stat, description }, index) => (
            <Grid key={`stat-${index}`} item xs={12} sm={12} md={4}>
              <S.StatWrapper>
                <S.Stat className="treez">
                  <CountUp
                    start={0}
                    end={separateNumbersFromLetters(stat, true)}
                    enableScrollSpy
                  />
                  <span>{separateNumbersFromLetters(stat)}</span>
                </S.Stat>
                <S.Description className="treez">{description}</S.Description>
              </S.StatWrapper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default StatsSection
