import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  padding-top: 1.5rem;
  padding-bottom: 0;
  background-color: white;
`

export const BlogsWrapper = styled(SectionWrapper)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: ${({ theme }) => theme.typography.pxToRem(104)};
    background-color: white;
  }
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding-top: ${({ theme }) => theme.typography.pxToRem(52)};
`

export const Title = styled.h1`
  .line {
    overflow: hidden;
  }

  visibility: hidden;

  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(62)};
  line-height: 74px;
  margin-bottom: 0.3em;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(80)};
    line-height: 96px;
    margin-bottom: 0.5em;
    font-weight: 500;
  }
`
