import React, { useState } from "react"
import * as S from "./updates-page.styles"
import { Container } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import FeaturedBlog from "../../components/featured-blog/featured-blog.component"
import BlogsPagination from "../../components/blogs-pagination/blogs-pagination.component"
import BlogsFilter from "../../components/blogs-filter/blogs-filter.component"

const UpdatesPage = ({ featuredUpdate }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpUpdate(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          uri
          date(formatString: "MMMM DD, YYYY")
          slug
          featuredImage {
            node {
              altText
              sourceUrl
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
          UpdatesBuilder {
            smallDescription
          }
        }
      }
    }
  `)
  const updates = staticQuery.allWpUpdate.nodes
  const updatesWithOutFeatured = updates.filter(
    ({ id }) => id !== featuredUpdate?.id
  )
  const [updatesToShow, setUpdatesToShow] = useState(updatesWithOutFeatured)
  console.log(updatesWithOutFeatured)
  return (
    <S.Wrapper>
      {featuredUpdate && (
        <Container maxWidth="xl">
          <FeaturedBlog
            title={featuredUpdate.title}
            date={featuredUpdate.date}
            description={featuredUpdate.UpdateBuilder?.smallDescription ?? ""}
            featuredImage={featuredUpdate.featuredImage}
            uri={`${featuredUpdate.slug}`}
          />
        </Container>
      )}
      <S.BlogsWrapper>
        <Container maxWidth="xl">
          <BlogsFilter
            setBlogsToShow={setUpdatesToShow}
            allBlogs={updates}
            blogs={updatesWithOutFeatured}
            title="Product Updates"
          />
          <BlogsPagination blogs={updatesToShow} />
        </Container>
      </S.BlogsWrapper>
    </S.Wrapper>
  )
}

export default UpdatesPage
