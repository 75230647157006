import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, IconButton, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import Arrow from "../../assets/arrow.svg"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { keyframes } from "@emotion/react"
import Slider from "react-slick"

export const CustomContainer = styled(Container)`
  position: relative;
  z-index: 4;
`

export const CustomAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

export const Wheel = styled(CustomImage)``

export const WheelWrapper = styled.div`
  //display: none;
  display: block;
  position: absolute;
  z-index: 2;
  width: 880px;
  height: 880px;
  bottom: -130%;
  right: -31%;

  @media (max-width: 460px) {
    width: 550px;
    height: 550px;
    bottom: -50%;
    right: -75%;
  }

  @media only screen and (max-width: 600px) and (min-width: 460px) {
    width: 650px;
    height: 650px;
    bottom: -50%;
    right: -58%;
  }

  @media only screen and (max-width: 735px) and (min-width: 600px) {
    width: 700px;
    height: 700px;
    bottom: -50%;
    right: -58%;
  }

  @media only screen and (max-width: 900px) and (min-width: 735px) {
    width: 750px;
    height: 750px;
    bottom: -50%;
    right: -50%;
  }

  @media only screen and (max-width: 1200px) and (min-width: 900px) {
    width: 800px;
    height: 800px;
    bottom: -50%;
    right: -40%;
  }

  @media (min-width: 1900px) {
    display: none;
  }
`

export const Wrapper = styled.section`
  background-color: white;
  overflow: unset;
  position: relative;

  padding-top: 3em;
  padding-bottom: 1em;

  .slick-list {
    overflow: unset;
    max-width: 1000px;
    margin: auto;
  }

  .slick-slide {
    padding: 0 1em;

    > div {
      transition: all 0.3s ease;
    }
  }

  .slick-slider {
    margin: 3em 0;
    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin: 4em 0;
    }
  }

  .slick-slide {
    opacity: 0.3;
    transition: 0.3s all ease;

    &.slick-active {
      &.slick-current {
        opacity: 1;
      }
    }
  }
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(39)};
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: 57px;
    font-weight: 400;
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 21px;
  }
`

export const Image = styled(CustomImage)`
  img {
    object-fit: contain !important;
  }
`

export const SliderWrapper = styled.div`
  position: relative;
`

export const CustomSlider = styled(Slider)`
  &.slick-slider {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      margin: 1.5em 0 !important;
    }
  }
`

export const ArrowButton = styled(IconButton)`
  display: none;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: block;
    width: 100px;
    position: absolute;
    top: 45%;
    left: 5%;
    transform: translate(-10%, -45%);
    &.right {
      top: 45%;
      left: 95%;
      transform: translate(-90%, -45%);
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const ButtonResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ArrowResponsiveButton = styled(IconButton)`
  display: none;
  width: 80px;
  @media (max-width: 900px) {
    display: block;
    &.right {
      > svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const ArrowWrapper = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 73, 20, 0.2);
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  &:hover {
    background-color: #004914;
    svg {
      fill: white;
    }
  }
`

export const CustomArrow = styled(ArrowBackIcon)`
  fill: #004914;
  width: 24px;
  height: 24px;
`
