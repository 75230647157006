import React, { useRef } from "react"
import PropTypes from "prop-types"
import * as S from "./hero.styles"
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import { CallMade } from "@mui/icons-material"

export const Hero = ({ tag, title, description, items }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      cardBg: file(relativePath: { eq: "card-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      smallCircle: file(relativePath: { eq: "small-circle.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const isMd = useMediaQuery(theme.breakpoints.only("md"))
  let slider = useRef()
  const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const cardContent = item => (
    <S.Card
      className={item?.style}
      {...(item?.style === "dark-green" && {
        img: staticQuery.cardBg,
      })}
    >
      {item?.style === "yellow" ? (
        <Grid container spacing={2}>
          <Grid item xs={5} md={12} lg={5}>
            <CustomImage img={staticQuery.smallCircle} className="circle" />
          </Grid>
          <Grid item xs={7} md={12} lg={7}>
            <S.CardTitle className={item?.style}>{item.title}</S.CardTitle>
          </Grid>
        </Grid>
      ) : (
        <>
          <S.CardTitle className={item?.style}>{item.title}</S.CardTitle>
          {item?.button && (
            <CustomButton href={item?.button?.url} className="hero" fullWidth>
              {item?.button?.title}
              {!isMd && (
                <S.Arrow>
                  <CallMade />
                </S.Arrow>
              )}
            </CustomButton>
          )}
        </>
      )}
    </S.Card>
  )
  const imageContent = item => (
    <S.ImagesWrapper className={`${item?.style} ${item?.title ? "space" : ""}`}>
      <S.BackgroundImage img={item.image} className={item.style} />
      {item?.style === "yellow" && item?.button && (
        <S.CustomLink
          spy={true}
          smooth={true}
          offset={-100}
          to={item?.button?.url}
        >
          Examples
        </S.CustomLink>
      )}
    </S.ImagesWrapper>
  )
  if (!items) return null
  return (
    <S.Wrapper id="overview">
      {isMobile ? (
        <>
          <Container>
            <S.ExtraTitle component="h2">{tag}</S.ExtraTitle>
            <S.Title component="h1">{title}</S.Title>
            <S.Paragraph>{description}</S.Paragraph>
          </Container>
          <S.SliderWrapper>
            <S.CustomSlider ref={c => (slider = c)} {...settings}>
              {items
                .filter(item => !item.hideonmobile)
                .map((item, index) => (
                  <S.WrapperLeft key={index}>
                    {item.image && imageContent(item)}
                    {item?.style === "yellow" && item?.title ? (
                      <S.CustomLink
                        spy={true}
                        smooth={true}
                        offset={-100}
                        to="our-mission"
                      >
                        {cardContent(item)}
                      </S.CustomLink>
                    ) : (
                      item?.title && cardContent(item)
                    )}
                  </S.WrapperLeft>
                ))}
            </S.CustomSlider>
          </S.SliderWrapper>
        </>
      ) : (
        <Container maxWidth={"xl"}>
          <S.ExtraTitle component="h2">{tag}</S.ExtraTitle>
          <S.Title component="h1">{title}</S.Title>
          <S.Paragraph>{description}</S.Paragraph>
          <S.CustomGrid container spacing={1.5}>
            {items?.map((item, index) => (
              <Grid item md sm xs={12} key={index}>
                <S.WrapperLeft>
                  {item.image && item.style !== "green" && imageContent(item)}
                  {item?.style === "yellow" && item?.title ? (
                    <S.CustomLink
                      spy={true}
                      smooth={true}
                      offset={-100}
                      to="our-mission"
                    >
                      {cardContent(item)}
                    </S.CustomLink>
                  ) : (
                    item?.title && cardContent(item)
                  )}
                </S.WrapperLeft>
              </Grid>
            ))}
          </S.CustomGrid>
        </Container>
      )}
    </S.Wrapper>
  )
}

Hero.propTypes = {
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: PropTypes.object,
}
