import styled from "@emotion/styled"
import CustomImage from "../custom-image/custom-image.component"
import CustomLink from "../custom-link/custom-link.component"
import CustomButton from "../custom-button/custom-button.component"
import Typography from "@mui/material/Typography"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import { Grid } from "@mui/material"

export const Wrapper = styled(CustomLink)`
  text-decoration: inherit;
  color: inherit;

  &:hover {
    cursor: pointer;

    h2 {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

export const BgImage = styled(CustomImage)`
  width: 150%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 340px;
  z-index: 1;
`

export const BlogImage = styled(CustomImage)`
  width: 100%;
  height: 100%;
  img {
    object-fit: cover !important;
    border-radius: 0 8px 8px 0;
    ${({ theme }) => theme.breakpoints.down("md")} {
      border-radius: 0 0 8px 8px;
    }
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 358px;
  }
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(52)};
  line-height: ${({ theme }) => theme.typography.pxToRem(68)};
  font-weight: 400;
  margin-top: ${({ theme }) => theme.typography.pxToRem(22)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(14)};
  color: #00330e;
  max-width: 850px;
  text-align: left;
  transition: all 0.3s ease-out 0s;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: 42px;
  }
`

export const Date = styled.span`
  position: relative;
  color: #191d23;
  margin-bottom: 2.5rem;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  opacity: 0.5;
`

export const GridContainer = styled(Grid)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 3rem;
  }
`

export const GridItem = styled(Grid)`
  position: relative;
  overflow: hidden;
`

// export const WheelBG = styled(Wheel)`
//   width: 80%;
//   position: absolute;
//   bottom: -45%;
//   left: 45%;
//   transform: translateY(10%);
//   z-index: 2;
//
//   ${({ theme }) => theme.breakpoints.down("md")} {
//     width: 60%;
//     bottom: -83%;
//     /* -webkit-transform: unset; */
//     -moz-transform: unset;
//     -ms-transform: unset;
//     /* transform: unset; */
//     left: 41%;
//   }
// `

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  z-index: 4;
  padding: ${({ theme }) => theme.typography.pxToRem(53)} 1rem;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: ${({ theme }) => theme.typography.pxToRem(88)}
      ${({ theme }) => theme.typography.pxToRem(100)};
  }
`

export const ImageContainer = styled.div`
  display: block;
  position: relative;

  //&:hover {
  //  .overlay {
  //    opacity: 1;
  //  }
  //}
`

export const ImageOverlay = styled.div`
  background-color: rgba(252, 74, 90, 0.8);
  border-radius: 10px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s;
`

export const Featured = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  line-height: ${({ theme }) => theme.typography.pxToRem(15)};
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const Button = styled(CustomButton)`
  max-width: 160px;
  position: relative;
  z-index: 2;
`
